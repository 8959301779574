import React from "react";
import { graphql } from "gatsby";
import withPreview from "../../utility/with-preview";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import classNames from "classnames";
import luceIcon from "../../images/flussi-icons/Icona_Luce_Plus.png";
import gasIcon from "../../images/flussi-icons/Icona_Gas_Plus.png";
import luceGasIcon from "../../images/flussi-icons/Icona_Luce_Gas_Plus.png";

const options = [
  [
    {
      id: "dual",
      value: "Luce e Gas",
      icon: luceGasIcon,
    },
    {
      id: "ele",
      value: "Luce",
      icon: luceIcon,
    },
    {
      id: "gas",
      value: "Gas",
      icon: gasIcon,
    },
  ],
];

const EntryPointProcessiInself = ({ data, location }) => {
  const { title, image } = data;

  return (
    <section className="section wrapper-container processi-inself__entry-point">
      <div className="container">
        <div className="d-flex justify-content-between flex-column flex-lg-row">
          <div className="align-self-center">
            {title?.value && (
              <h1 className="section__title processi-inself__title">{title.value}</h1>
            )}
            <div className="processi-inself__main-content-plus">
              {options?.map((row, i) => (
                <div className="processi-inself__answer-plus" key={i}>
                  {row?.map((option, j) => (
                    <div
                      className={classNames(
                        "processi-inself__option-block-plus d-flex justify-content-center mb-2 mb-md-0"
                      )}
                      key={j}
                    >
                      <button
                        className={classNames(
                          "processi-inself__option-plus d-flex align-items-center justify-content-center flex-column",
                          option?.id && `processi-inself__option-plus--${option.id}`
                        )}
                        onClick={() => {
                          const wind = window.open("/configuratore-prodotti#step2");
                          wind.sessionStorage.setItem("#step1", option.id);
                          wind.sessionStorage.setItem("#pageFrom", location?.pathname);
                        }}
                      >
                        <span className="d-flex justify-content-center align-items-center">
                          <img src={option?.icon} alt={option?.value} />
                        </span>
                        <span className="processi-inself__option-label-plus">{option?.value}</span>
                      </button>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
          <div className="align-self-center">
            {image?.node && (
              <GatsbyImage image={getImage(image.node)} alt={image?.value?.description || ""} />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default withPreview(EntryPointProcessiInself);
export const fragment = graphql`
  fragment JskEntryPointProcessiInselfFragment on LiferayJskEntryPointProcessiInself {
    liferayFields {
      siteId
      articleId
    }
    title {
      value
    }
    image {
      node {
        gatsbyImageData
      }
      value {
        description
      }
    }
  }
`;
